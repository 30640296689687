import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'

import * as icons from './icons'

// TODO: create a nice loader
export function Spinner(props) {
  return (
    <common.Box fontSize={8} display="flex" justifyContent="center">
      <icons.Spinner delay={props.delay} />
    </common.Box>
  )
}

Spinner.propTypes = {
  delay: PropTypes.bool,
}
